<template>
  <div style="overflow: hidden;">
    <div>
      <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
      ></NavBar>
    </div>
    <v-app>
      <div align="center">

        <!-- Edit Coupon Popup dialog starts -->
        <div>
            <v-row justify="center">
              <v-dialog v-model="editCouponPopupFlag" persistent max-width="700">
                <v-card>
                  <v-card-title class="editCouponDialogTitle">
                    <div>
                      <p style="font-weight:bold;margin-top:10px" >
                        Edit Coupon Details
                      </p>
                    </div>
                    <div>
                      <v-btn  icon @click="editCouponPopupFlag=false">
                      <img src="@/assets/popupClose.svg" >
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div>
                        <div>
                            <div class="titleAndTextFiledClass">
                                <div>
                                    <p style="text-align:left">Coupon Name</p>
                                </div>
                                <div>
                                    <v-text-field disabled
                                    v-model="editCouponName"
                                    placeholder=editCouponName
                                    solo
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div class="titleAndTextFiledClass">
                                <div>
                                    <p style="text-align:left">Coupon Description<span style="color:red">*</span></p>
                                </div>
                                <div>
                                    <v-textarea
                                    v-model="editCouponDescription"
                                    placeholder=editCouponDescription
                                    solo
                                    width="100%"
                                    :rules="couponDescriptionRules"
                                    >
                                    </v-textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                  </v-card-text>
                  <v-card-actions style="display:flex; justify-content:flex-end">
                    <div >
                      <v-btn
                      color="primary"
                      width="100px"
                      @click="editCoupon()"
                      :disabled="isButtonClicked || editCouponDescription == '' || editCouponDescription.trim().length >= 160"
                      >
                      Save
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
        </div>
        <!-- Edit Coupon Popup dialog ends -->
        
        <!-- date validation dialog starts -->
        <div>
          <v-row justify="center">
            <v-dialog v-model="dateValidationModal" persistent max-width="300">
              <v-card>
                <v-card-title style="word-break: normal; padding: 5%">
                  Start Date cannot be greater than End Date
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                  color="primary"
                  @click="dateValidationModal = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-dialog v-model="free_appointment_warning" persistent max-width="300">
              <v-card>
                <v-card-title style="word-break: normal; padding: 5%">
                  Discount amount is higher than minimum spend amount. Might result in free appointment. <br> Ok to proceed. Cancel to change
                </v-card-title>
                <div style="display: flex; justify-content: space-between;">
                  <div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                      color="primary"
                      @click="closeFreeWarning('cancel')"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </div>
                  <div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                      color="warning"
                      @click="closeFreeWarning('ok')"
                      >
                        Ok
                      </v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <!-- date validation dialog ends -->
        <!-- Discount validation dialog starts -->
        <div>
          <v-row justify="center">
            <v-dialog v-model="discountErrorModal" persistent max-width="300">
              <v-card>
                <v-card-title style="word-break: normal; padding: 5%">
                  Discount amount cannot greater than or equal to Minimun spent amount
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                  color="primary"
                  @click="discountErrorModal = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <!-- Discount validation dialog ends -->
        <v-dialog v-model="coupon_model" max-width="60%" persistent style="overflow: hidden;">
          <v-card>
            <div class="TitleHeader">
              <div>
                <v-card-title class="TitleText">
                  Create Coupon Family
                </v-card-title>
              </div>
            </div>
            <div align="left" style="overflow: hidden;">
              <table class="tableClass">
                <tr>
                  <td style="padding: 16px;">Coupon Name</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="coupon_family_name" label="Coupon Name"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Coupon Description</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="coupon_family_description" label="Coupon Description"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Coupon Type</td>
                  <td style="padding: 16px; vertical-align:middle;"><v-select label="Coupon Type" hide-details="auto" v-model="selected_coupon_type" :items="coupon_types_list" outlined></v-select></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Discount Value</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="coupon_family_discount_value" label="Discount" @wheel="$event.target.blur()" class="hideArrows" type="number" :prefix = "selected_coupon_type !='PERCENTAGE_OFF' ? 'MMK' : ''" :suffix="selected_coupon_type =='PERCENTAGE_OFF' ? '%' : ''"></v-text-field></td>
                </tr>
                <tr v-if="selected_coupon_type == 'PERCENTAGE_OFF'">
                  <td style="padding: 16px;">Max Discount Amount ( 9999999 if no upper limit )</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="coupon_family_max_discount_amount" label="Max discount" @wheel="$event.target.blur()" class="hideArrows" type="number" prefix="MMK"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Minimum Spend amount ( 0 if not applicable )</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="coupon_family_minimum_spend_amount" label="Min spend" @wheel="$event.target.blur()" class="hideArrows" type="number" prefix="MMK"></v-text-field></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Discount Scope</td>
                  <td style="padding: 16px;"><v-select label="Discount target category" hide-details="auto" v-model="selected_target_group" :items="all_target_list" outlined @change="selected_list_values = []"></v-select></td>
                </tr>
                <tr v-if="selected_target_group == 'Specialization'">
                  <td style="padding: 16px;">List</td>
                  <td style="padding: 16px;">
                    <v-select label="Select Applicable On" hide-details="auto" v-model="selected_list_values" multiple :items="all_spec_list" item-text="specialization_name" item-value="_id" outlined>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.specialization_name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                          >
                          (+{{ selected_list_values.length - 1 }} others)
                        </span>
                      </template>
                    </v-select></td>
                </tr>
                <tr v-if="selected_target_group == 'Doctor'">
                  <td style="padding: 16px;">List</td>
                  <td style="padding: 16px;">
                    <v-select label="Select Applicable On" hide-details="auto" v-model="selected_list_values" multiple :items="all_doc_list" item-text="doctor_name" item-value="_id" outlined>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.doctor_name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                          >
                          (+{{ selected_list_values.length - 1 }} others)
                        </span>
                      </template>
                    </v-select></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Valid From</td>
                  <td style="padding: 16px;">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="valid_from" label="Dates List" background-color="#F4F6FA" outlined v-bind="attrs" v-on="on" style="border-radius:8px;" ></v-text-field>
                      </template>
                      <v-date-picker style="padding:20px; height: 500px;color: black" v-model="valid_from" show-adjacent-months :allowed-dates="allowedDates"></v-date-picker>
                    </v-menu>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Valid Till</td>
                  <td style="padding: 16px;">
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="valid_till" label="Dates List" background-color="#F4F6FA" outlined v-bind="attrs" v-on="on" style="border-radius:8px;" ></v-text-field>
                      </template>
                      <v-date-picker @change="dateValidator" style="padding:20px; height: 500px;color: black" v-model="valid_till" show-adjacent-months :allowed-dates="allowedDates"></v-date-picker>
                    </v-menu></td>
                </tr>
                <tr>
                  <td style="padding: 16px;">Number of Coupons</td>
                  <td style="padding: 16px;"><v-text-field hide-details="auto" v-model="coupon_quantity" label="Number of Coupons" @wheel="$event.target.blur()" class="hideArrows" type="number"></v-text-field></td>
                </tr>
              </table>
            </div>
            <div style="display: flex;justify-content: flex-end;">
              <div style="padding: 16px">
                <v-btn class="primary"
                @click="createCoupons"
                :disabled="checkDisabled()"
                >Create</v-btn>
              </div>
              <div style="padding: 16px">
                <v-btn class="error" @click="closePopup">Close</v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
      <v-row style="height: 96vh; background-color: #f5faff;">
        <v-col cols="2">
          <SideBar :pageName="pageName"></SideBar>
        </v-col>
        <v-col cols="9">
         <div style="margin:10px 20px 20px 50px">
          <div v-if="!isLoading" align="left" style="display: flex; flex-direction: row; justify-content: space-between;">
            <div style="width: 30%" align="left">
              <span class="TitleClass">
               <h3> Coupon Management</h3>
              </span>
            </div>
            <div class="d-flex justify-space-around" style="margin:10px 20px 20px 50px;">
              <div class='px-2'>
                <v-select
                  v-model="selected_search_type"
                  :items="search_type"
                  label="Filter type"
                  solo
                  dense
                  style="width: 300px; z-index: 15;"
                  @change = "search_value = ''"
                />
              </div>
              <div class="px-2">
                <v-text-field
                  v-model="search_value"
                  label="Search Coupon Code Availability"
                  v-if="selected_search_type == 'Coupon Code Availability'"
                  solo
                  dense
                  style="width: 250px;"
                  >
                </v-text-field>
                <v-text-field
                  v-model="search_value"
                  label="Search coupon name"
                  v-else-if="selected_search_type == 'Coupon Name'"
                  solo
                  dense
                  style="width: 250px;
                    padding: 3%;
                    border-radius: 5px;"
                  >
                </v-text-field>
                <input 
                    type="date" 
                    v-model="search_value" 
                    placeholder="Starting Date"
                    solo
                    dense
                    style="width: 250px;background-color: white;
                    padding: 3%;
                    box-shadow: 0px 8px 30px 0px #0547891a;
                    color: #1467bf;
                    border-radius: 5px;"
                    v-else-if="selected_search_type == 'Start Date'"
                />
                <input 
                    type="date" 
                    v-model="search_value" 
                    placeholder="Created Date"
                    solo
                    dense
                    style="width: 250px; background-color: white;
                    padding: 3%;
                    box-shadow: 0px 8px 30px 0px #0547891a;
                    color: #1467bf;
                    border-radius: 5px;"
                    v-else-if="selected_search_type == 'Created Date'"
                />
                <input 
                    type="date" 
                    v-model="search_value" 
                    placeholder="Ending Date"
                    solo
                    dense
                    style="width: 250px; background-color: white;
                    padding: 3%;
                    box-shadow: 0px 8px 30px 0px #0547891a;
                    color: #1467bf;
                    border-radius: 5px;"
                    v-else-if="selected_search_type == 'End Date'"
                />

                <v-select
                  v-model="search_value"
                  :items="avail_list"
                  label="Search By Coupon Availability"
                  v-else-if="selected_search_type == 'Coupon Availability Status'"
                  solo
                  dense
                  style="width: 250px; z-index: 15;"
                  >
                </v-select>
              </div>
              <div class="px-2" v-if="search_value">
                <v-btn class = "primary" style="border-radius: 8px; " @click="filterCoupons()" :disabled="search_disable">
                  Search 
                  <span style="font-size: 18pt;"> <v-icon>mdi-magnify</v-icon></span>
                </v-btn>
              </div>
              
              <div>
                <div style="border-radius: 8px;">
                  <span style="font-size: 26pt; display: flex; justify-content: space-between;align-items: end;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }" style="display: flex; justify-content: end; align-items: center;">
                        <v-btn :disabled="isAddDisabled" append rounded color="primary" v-on="on" style="border-radius: 8px; height: 35px;" @click="openCreatePopup">
                          <span style="font-size: 18pt;"> + </span>
                          <span style="font-weight: 600;"> Create Coupons</span>
                          <span v-if="isAddDisabled">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </span>
                        </v-btn>
                      </template>
                      <span>Create Coupons</span>
                    </v-tooltip>
                  </span>
                </div>
              </div>

            </div>
          </div>
          <div align="center"  v-if="isLoading">
            <div>
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
          </div>
          <div align="center" v-else-if="!isLoading && search_avail_coupon">
            <div class="fixTableHead2">
              <table class="tableClass" style="width: 100%;">
                <tr align="center">
                  <th style="padding-left:2%">Coupon code</th>
                  <th>Used on</th>
                  <th>User Name</th>
                  <th>Speciality</th>
                  <th>Doctor Name</th>
                </tr>
                <tr align="center">
                  <td style="padding-left:2%">{{ searched_coupon.coupon_code }}</td>
                  <td>{{ searched_coupon.availability != ''? searched_coupon.availed_on : "-" }}</td>
                  <td>{{ searched_coupon.availability != ''? searched_coupon.availed_by_name : "-" }} </td>
                  <td>{{ searched_coupon.availability != ''? searched_coupon.availed_on_spec : "-" }} </td>
                  <td>{{ searched_coupon.availability != ''? searched_coupon.availed_on_doc : "-" }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div align="center" v-if="!isLoading && !isSearchLoading">
            <div class="fixTableHead">
              <table id="coupon_master_table" class="tableClass" v-if="allCoupons.length > 0">
                <tr>
                  <th>Created On</th>
                  <th>Coupon Name</th>
                  <th>Description</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Total Coupons</th>
                  <th>Coupons Used</th>
                  <th>Coupons Unused</th>
                  <th>Availability Status</th>
                  <th>Coupon Status</th>
                  <th>Actions</th>
                </tr>
                <tr align="center" v-for="coupon in allCoupons" :key="coupon._id">
                  <td>{{ coupon.createdAt.split("T")[0] }}</td>
                  <td>{{ coupon.master_coupon_code }}</td>
                  <td>{{ coupon.coupon_description }}</td>
                  <td>{{ coupon.valid_from }}</td>
                  <td>{{ coupon.valid_till }}</td>
                  <td>{{ coupon.coupon_quantity }}</td>
                  <td>{{ coupon.used_coupons.length }} </td>
                  <td>{{ coupon.coupon_quantity - coupon.used_coupons.length}}</td>
                  <td>
                    <v-switch
                    :loading = "isEditLoading"
                    :disabled = "isDisabled"
                    v-model="coupon.couponStatus"
                    @change="changeStatusOfCoupon(coupon)"
                    ></v-switch>
                  </td>
                  <td :class="coupon.class">{{ coupon.available_status }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a style="width: 100%; margin:5px;" icon @click="viewParticularCouponFamily(coupon._id)" depressed v-on="on">
                          <img src="https://d3vsnl8idgwrlw.cloudfront.net/walkin_view.svg" style="height: 24px; width: 24px;"/>
                        </a>

                        <a style="width: 100%; margin:5px;" icon @click="editCouponPopup(coupon);" depressed v-on="on">
                          <img src="https://d3vsnl8idgwrlw.cloudfront.net/edit.svg" style="height: 24px; width: 24px;"/>
                        </a>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                  </td>
                </tr>
              </table>
              <div align="center" v-else>
                No coupons generated
              </div>
            </div>
          </div>
        </div></v-col>
      </v-row>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "ViewAllCoupons",
  components: {
    NavBar,SideBar,
  },
  data() {
    return {
      pageName: "CouponFamily",
      currentUser: "",
      currentUserId: "",
      currentUserName: "",
      isLoading: false,
      allCoupons: [],
      allStaticCoupons: [],
      search_type: ['Coupon Code Availability', 'Coupon Name',  'Coupon Availability Status', 'Start Date', 'End Date'],
      selected_search_type: null,
      search_value: null,
      find_coupon: "",
      coupon_model:false,
      isAddDisabled: false,
      selected_coupon_type: null,
      coupon_types_list: ["PERCENTAGE_OFF", "FLAT_OFF"],
      coupon_family_max_discount_amount: null,
      coupon_family_name: "",
      coupon_family_description: "",
      coupon_family_discount_value: "",
      coupon_family_minimum_spend_amount: null,
      all_target_list: ["Specialization","Doctor"],
      selected_target_group: "",
      selected_list_values: "",
      menu: false,
      menu2: false,
      valid_from: "",
      valid_till: "",
      coupon_quantity: null,
      all_doc_list: [],
      all_spec_list: [],
      dateValidationModal:false,
      discountErrorModal : false,
      search_disable: false,
      isButtonClicked: false,
      free_appointment_warning: false,
      warning_accepted: false,
      search_avail_coupon: false,
      searched_coupon: {},
      isSearchLoading: false,
      search_value_start_range: null,
      search_value_end_range: null,
      avail_list: ['Available', 'Expired'],
      couponStatus: false,
      editCouponPopupFlag: false,
      couponId: '',
      editCouponName: '',
      editCouponDescription: '',
      couponDescriptionRules:
      [ 
        v =>  (v.trim().length < 160) || 'Description must be 160 characters or less.',
        v => v.trim() != '' || "Description can not be empty"
      ],
      isEditLoading: false,
      isDisabled: false,
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      this.isLoading = true;
      var get_admin_body = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType: "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/getAdmin",get_admin_body)
      .then((adminReadSuccess) =>{
        this.currentUserName = adminReadSuccess.data.data.admin_name;
        this.currentUserId = adminReadSuccess.data.data._id;
        var data = {
          token : this.currentUser,
          typeOfUser : "ADMIN"
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + "/readAllCouponFamilies", data)
        .then((successResponse) => {
          this.isLoading = false;
          this.isSearchLoading = false;
          this.allCoupons = successResponse.data.data;
          var today_date = new Date().toISOString().split("T")[0];
          this.allCoupons.forEach((x) =>{
            if((x.valid_from > today_date) || (x.used_coupons.length >= x.coupon_quantity) ||(x.valid_till < today_date))
            {
              x.available_status = "Expired";
            }
            else
            {
              x.available_status = "Available";
            }
            x.couponStatus = x.status == "ACTIVE" ? true : false;
            x.class= (x.available_status != "Available") ? "expired" : "available";
          });
          this.allStaticCoupons = this.allCoupons;
        })
        .catch((errorResponse) => {
          this.isLoading = false;
          window.alert("Error: ", errorResponse);
          console.log(errorResponse);
        });
      })
      .catch((adminReadError) =>{
        this.isLoading = false;
        window.alert("Error: ", adminReadError);
        console.log(adminReadError);
      });
    }
  },
  methods: {
    editCouponPopup(coupon) {
      this.couponId = coupon._id;
      this.editCouponName = coupon.master_coupon_code;
      this.editCouponDescription = coupon.coupon_description;
      this.editCouponPopupFlag = true;
    },
    editCoupon() {
      this.isButtonClicked = true;
      let editCouponMasterBody = {
        token: this.currentUser,
        typeOfUser: 'ADMIN',
        couponId: this.couponId,
        couponDescription: this.editCouponDescription
      };

      axios.post(process.env.VUE_APP_BACKEND_URL + '/editCouponMaster', editCouponMasterBody)
      .then(() => {
        this.editCouponPopupFlag = false;
        window.alert('Coupon edited successfully');
        this.$router.go();
      })
      .catch((editCouponMasterError) => {
        if (editCouponMasterError.response.status == 401) {
          window.alert('Sorry, You are unauthorized! Please login again.');
        } else {
          window.alert('Something went wrong...');
        }
        this.editCouponPopupFlag = false;
        this.$router.go();
      });
    },
    changeStatusOfCoupon(item) {
      this.isEditLoading = true;
      this.isDisabled = true;
      let changeCouponStatusBody = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        couponId: item._id,
        status: item.couponStatus == true ? "ACTIVE" : "INACTIVE"
      };

      axios.post(process.env.VUE_APP_BACKEND_URL + "/changeCouponStatus", changeCouponStatusBody)
      .then((changeCouponStatusResponse) => {
        this.isEditLoading = false;
        this.isDisabled = false;
      })
      .catch((changeCouponStatusError) => {
        if (changeCouponStatusError.response.status == 401) {
          window.alert('Sorry, You are unauthorized! Please login again.');
        } else {
          window.alert('Something went wrong...');
        }
        this.$router.go();
      });
    },
    closeFreeWarning(buttonType)
    {
      this.free_appointment_warning = false;
      if(buttonType == 'ok')
      {
        this.warning_accepted = true;
        this.createCoupons();
      }
      else
      {
        this.isButtonClicked = false;
      }
    },
    checkDisabled()
    {
      return (!(this.coupon_family_name != '' && this.selected_coupon_type != '' && this.coupon_family_discount_value != '' && this.selected_coupon_type != '' && this.valid_from != '' && this.valid_till != '' && this.selected_target_group !='' && this.selected_list_values !='' && this.valid_from < this.valid_till && this.coupon_quantity > 0 && this.coupon_family_minimum_spend_amount >= 0 && parseInt(this.coupon_family_discount_value) > 0 && (((this.selected_coupon_type == 'FLAT_OFF' && parseInt(this.coupon_family_minimum_spend_amount) >= 0) || (this.selected_coupon_type == 'PERCENTAGE_OFF' && parseInt(this.coupon_family_discount_value) < 100 && parseInt(this.coupon_family_max_discount_amount) >= 0)))) || this.isButtonClicked);
    },
    filterCoupons()
    {
      this.search_disable = true;
      this.search_avail_coupon = false;
      this.isSearchLoading = true;
      if(this.selected_search_type == 'Coupon Code Availability')
      {
        let coupon_code = this.search_value;
        var coupon_body = {
          couponCode: coupon_code,
          token: this.currentUser,
          typeOfUser: "ADMIN"
        }

        axios.post(process.env.VUE_APP_BACKEND_URL+"/readCouponByCode", coupon_body)
        .then((couponResponse) => {
          this.search_disable = false;
          this.isSearchLoading = false;
          if(couponResponse.data.data)
          {
            this.searched_coupon = couponResponse.data.data;
            this.search_avail_coupon = true;
            this.allCoupons = couponResponse.data.couponMasterData;
            var today_date = new Date().toISOString().split("T")[0];
            this.allCoupons.forEach((x) =>{
              if((x.valid_from > today_date) || (x.used_coupons.length >= x.coupon_quantity) ||(x.valid_till < today_date))
              {
                x.available_status = "Expired";
              }
              else
              {
                x.available_status = "Available";
              }
              x.class= (x.available_status != "Available") ? "expired" : "available";
            });
          }
          else
          {
            window.alert("No such coupon found");
            this.allCoupons = this.allStaticCoupons;
          }
        })
        .catch((couponError) =>{
          console.log(couponError)
          this.search_disable = false;
          if (couponError.response.status == 404) {
            window.alert("No such Coupon exists");
          } else {
            window.alert("Error while getting coupon availability");
          }
        });
      }
      else
      {
        var coupon_body = {
          filter_value: this.search_value,
          token: this.currentUser,
          typeOfUser: "ADMIN"
        }
        if(this.selected_search_type == 'Created Date')
        {
          coupon_body['filter_type'] = 'createdOn'
        }
        else if(this.selected_search_type == 'Coupon Name')
        {
          coupon_body['filter_type'] = 'couponName'
        }
        else if(this.selected_search_type == 'Start Date')
        {
          coupon_body['filter_type'] = 'startDate'
        }
        else if(this.selected_search_type == 'End Date')
        {
          coupon_body['filter_type'] = 'endDate'
        }
        else if(this.selected_search_type == 'Coupon Availability Status')
        {
          coupon_body['filter_type'] = 'availabilityStatus'
        }

        axios.post(process.env.VUE_APP_BACKEND_URL+"/filterCoupons", coupon_body)
        .then((filterSuccess) => {
          console.log(filterSuccess.data.data);
          this.search_disable = false;
          this.isSearchLoading = false;
          this.allCoupons = filterSuccess.data.data;
          var today_date = new Date().toISOString().split("T")[0];
          this.allCoupons.forEach((x) =>{
            if((x.valid_from > today_date) || (x.used_coupons.length >= x.coupon_quantity) ||(x.valid_till < today_date))
            {
              x.available_status = "Expired";
            }
            else
            {
              x.available_status = "Available";
            }
            x.class= (x.available_status != "Available") ? "expired" : "available";
          });
          this.allStaticCoupons = this.allCoupons;
        })
        .catch((filterError) =>{
          this.search_disable = false;
          this.isSearchLoading = false;
          console.log(filterError);
        });
      }
    },
    allowedDates(val)
    {
      return val >= new Date().toISOString().split("T")[0];
    },
    viewParticularCouponFamily(coupon_id)
    {
      this.$router.push({
        path: '/admin/viewCouponFamily/'+coupon_id
      });
    },
    openCreatePopup()
    {
      this.isAddDisabled = true;
      this.coupon_model = true;
      var all_request = {
        token : this.currentUser,
        typeOfUser : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/readAllSpecializations", all_request)
      .then((all_spec_response) =>{
        console.log(all_spec_response);
        this.all_spec_list = all_spec_response.data.data;
      })
      .catch((all_spec_error) =>{
        console.log(all_spec_error)
      });
      axios.post(process.env.VUE_APP_BACKEND_URL + "/getAllAvailableDoctors", all_request)
      .then((all_doc_response) => {
        this.all_doc_list = all_doc_response.data.data;
      })
      .catch((all_doc_error) =>{
        console.log(all_doc_error);
      });
    },
    createCoupons()
    {
      this.isButtonClicked = true;
      var CouponGenerationbody = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        masterCouponCode:this.coupon_family_name,
        couponType:this.selected_coupon_type,
        discount:parseInt(this.coupon_family_discount_value),
        maxDiscount: this.selected_coupon_type == "FLAT_OFF"? parseInt(this.coupon_family_discount_value) : parseInt(this.coupon_family_max_discount_amount) > 0 ? parseInt(this.coupon_family_max_discount_amount) : 9999999,
        minSpend:parseInt(this.coupon_family_minimum_spend_amount),
        doctorFeeSplit:0,
        commissionFeeSplit:100,
        couponQuantity:parseInt(this.coupon_quantity),
        validFrom:this.valid_from,
        validTill:this.valid_till,
        applicableOn:[this.selected_target_group],
        applicableList:this.selected_list_values,
        generatedBy:this.currentUserId,
        description:this.coupon_family_description
      };
      if(this.selected_coupon_type == 'PERCENTAGE_OFF' || (this.selected_coupon_type == 'FLAT_OFF' && (parseInt(this.coupon_family_minimum_spend_amount) > parseInt(this.coupon_family_discount_value)) || this.warning_accepted))
      {
        axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/generateCouponFamily",
          CouponGenerationbody
          )
        .then((generateCouponFamilyResponse) => {
          console.log("Response",generateCouponFamilyResponse)
          alert("Coupon Generated Successfully")
          this.isAddDisabled = false
          this.coupon_model = false
          this.isButtonClicked = false;
          this.$router.go();
        })
        .catch((generateCouponFamilyError) => {
          this.isAddDisabled = false
          console.log("Error",generateCouponFamilyError);
          this.isButtonClicked = false;
        });
      }
      else
      {
        this.free_appointment_warning = true;
      }
    },
    dateValidator(){
      if(this.valid_from > this.valid_till)
      {
        this.dateValidationModal = true
        this.valid_till = ''
      }
    },
    closePopup()
    {
      console.log("Clicked");
      this.coupon_model = false;
      this.isAddDisabled = false;
    },
    discountValidation(){
      if(this.coupon_family_discount_value && parseInt(this.coupon_family_discount_value) >= parseInt(this.coupon_family_minimum_spend_amount) && this.selected_coupon_type == 'FLAT_OFF'){
        this.discountErrorModal = true
      }
    }
  },
};
</script>

<style>

  .hideArrows input::-webkit-outer-spin-button,
  .hideArrows input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  /* Firefox */
  .hideArrows input[type=number] {
    -moz-appearance: textfield !important;
  }
</style>
<style scoped>
.TitleClass
{
  font-weight: 800;
  font-size: 12pt;
  /* line-height: 29px; */
  padding-left: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: lightgrey !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888 !important;
}
.titleAndTextFiledClass{
    display: flex;
    flex-direction: column;
}
.editCouponDialogTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.coupon_desc_class
{
  border-style: none groove none none; padding: 16px;
}
.coupon_details_title
{
  font-size: 16px;
  line-height: 24px;
  color: #828282;
  padding: 4px;
}
.coupon_details_value
{
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  padding: 4px;
}
.fixTableHead {
  overflow-y: auto;
  height: 50vh;
}
.fixTableHead2 { 
  overflow-y: auto;
  height: 20vh;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
.fixTableHead2 thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  border: 0px;
}
td {
  padding: 2px;
  font-size: 8pt;
  font-weight: 700;
  padding-left: 5px;
  text-align: left;
  /* width: 80px; */
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
  /* padding-left: 10px;
  padding-right: 10px; */
  text-align: left;
  /* width:80px; */
}
tr {
  border-bottom: #d4eeff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  overflow-y: scroll;
}
table > tr > td:last-child {
    white-space: nowrap;
}
.expired
{
  color: lightcoral;
}
.available
{
  color: lightgreen;
}
</style>